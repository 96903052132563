import { Injectable } from '@angular/core';

@Injectable()
export class HttpStatusCodeService {
  private statusCode: number;

  constructor() {
    this.statusCode = 200;
  }

  public setStatusCode(statusCode: number) {
    this.statusCode = statusCode;
  }

  public getStatusCode(): number {
    return this.statusCode;
  }
}
