import { Component, OnInit, Input, Optional } from '@angular/core';
import { HttpStatusCodeService } from './core/http-status-code.service';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404">
      <app-header
        [cssClass]="'type--404'"
        [inverted]="true"
        (inView)="(true)"
        [subtitle]="'error-404-text' | fromDictionary"
        [title]="'error-404-title' | fromDictionary"
      ></app-header>
    </article>
  `,
  styles: [
    '.error-404__content { color:black; position: absolute; bottom: 80px;left:50%;text-align:center;transform: translateX(-50%); z-index:16; }',
  ],
})
export class NotFoundComponent implements OnInit {
  constructor(@Optional() private statusCode: HttpStatusCodeService) {
    this.statusCode?.setStatusCode(404);
  }
  ngOnInit() {}
}
