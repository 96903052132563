import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { NotFoundComponent } from './404.component';
import { ContentPagesGuard } from './content/guards';
import { ProjectsGuard } from './projects/guards';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
    },
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: true,
    },
  },
  {
    path: 'purpose',
    loadChildren: () => import('./purpose/purpose.module').then(m => m.PurposeModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
    },
  },
  {
    path: 'people',
    loadChildren: () => import('./people/people.module').then(m => m.PeopleModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
    },
  },
  {
    path: 'awards-and-publications',
    loadChildren: () => import('./awards/awards.module').then(m => m.AwardsModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
    },
  },
  {
    path: 'pressroom',
    loadChildren: () => import('./press-room/press-room.module').then(m => m.PressRoomModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
    },
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
    },
  },
  {
    path: 'style-guide',
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    pathMatch: 'full',
  },
  {
    path: 'page/12/Home', // redirect homepage ipv content page to home
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: 'page/2/Contact', // redirect contact ipv content page to contact
    redirectTo: 'contact',
    pathMatch: 'full',
  },
  {
    path: 'page/8/Community', // redirect people ipv content page to community
    redirectTo: 'people',
    pathMatch: 'full',
  },
  {
    path: 'page/1/Key-Values', // redirect purpose ipv content page to key values
    redirectTo: 'purpose',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [DictionaryGuard, ContentPagesGuard, CookieSettingsGuard],
    data: {
      dark: false,
      topbar: {
        title: '404',
        setRow: false,
      },
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
